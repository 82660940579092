import React from "react";
import Card from 'react-bootstrap/Card';

const NotFound = () => (
  <center>
    <div>
      <Card style = {{ paddingInline: '1em', alignContent: 'left' }}>      
        <div style = {{height: "100px"}} />
        <b className = "title">404 - Page Not Found</b>
        <br />
        <p>This page does not exist!</p>
        <br />
        <div style = {{height: "100px"}} />
      </Card>
    </div>
  </center>
)

export default NotFound
